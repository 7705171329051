import React from "react"

import Layout from "components/Layout/en"
import Product from "components/Layout/Product/index"
import productImg from "./product.jpg"
import productIcon from "./WeldScanner.svg"
import { Table } from "react-bootstrap"
import { MdDone, MdCancel } from "react-icons/md"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Reduce the time for measuring weld seams (e.g. according to DIN EN
        17637) to less than one second! The MoonVision software automatically
        and precisely distinguishes the weld from the workpiece. Using a camera
        the system measures whether the result of the welding process fulfills
        your quality requirements. Different seam types are recognized and taken
        into account.
      </p>
      <h3>Highlights</h3>
      <ul>
        <li>Automated quality inspection of weld seams</li>
        <li>Benefit from consistent quality and reduced lead times</li>
        <li>Detection and measurement with a camera & software</li>
        <li>Highest accuracy & comfort through artificial intelligence</li>
        <li>Visual Output or forwarding to subsequent systems</li>
      </ul>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="WeldScanner"
        title="Quality inspection of welds in real time"
        subtitle="Efficient Software for Weld seam analysis - easy to operate and in real time"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Automated weld testing",
          "Consistently high quality",
          "Shortened lead time",
          "Find out now",
        ]}
        technicalTable={
          <Table responsive size="sm">
            <thead>
              <tr>
                <th colSpan="2">Examination of</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Form</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Base</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Symmetry/appearance</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Transitions</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Defect detection</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Cracks</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Undercuts</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Deviation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Elevation </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Notches</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Bubbles</td>
                <td>
                  <MdCancel />
                </td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th colSpan="2">Application</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>All material types can be checked</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>On-site installation possible</td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>Cloud Application possible</td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <p>
            The MoonVision WeldScanner was specially developed for post-weld
            quality inspection. New technologies such as machine learning are
            used to distinguish between different types and properties of welds.
            They are detected as accurately as possible and measured. Tests
            ensure that your welds comply with applicable standards. You can
            also define your own test rules.
          </p>
          <p>
            The artificial intelligence used outshines existing approaches with
            regard to inspection accuracy - the evaluation process is fully
            automated. You profit from the time savings.
          </p>
          <p>
            The MoonVision software supports you among other things in the
            evaluation after:
          </p>
          <ul>
            <li>Din EN 17637 </li>
            <li>DIN EN 5817 (mit Zuordnung zu Bewertungsgruppen)</li>
            <li>DIN EN 13018 </li>
            <li>DIN EN 10042</li>
          </ul>
          <p>
            The MoonVision WeldScanner can be used at different production steps
            ranging from the inspection to the incoming to the outgoing goods.
            Due to its self-sufficient design, the software can be integrated
            into a running production process or existing ERP systems.
          </p>
          <p>At the end of the process, results can also be:</p>
          <ul>
            <li>
              Displayed visually(on a monitor/alarms/storage of jpg files)
            </li>
            <li>Transmitted in tabular form</li>
            <li>Passed on to management information systems via APIs</li>
            <li>Used for process control via APIs</li>
          </ul>
          <p>Thanks to the MoonVision concept</p>
          <ul>
            <li>Individual adaptations can be carried out independently</li>
            <li>You stay in control of access and checkable parameters</li>
          </ul>
          <p>Thanks to the MoonVision concept</p>
          <ul>
            <li>Individual adaptations can be carried out independently</li>
            <li>You stay in control of access and checkable parameters</li>
          </ul>
          <h3>Available where you need it</h3>
          <p>
            The hardware for using the MoonVision WeldScanner can be installed
            directly at your site, but can also be made available online. No
            matter which option you choose, you have access to the MoonVision
            Toolbox and can make adjustments at any time, e.g. for parameter
            adaptation and personalisation.
          </p>
          <h3>Simple to use</h3>
          <p>
            {" "}
            Due to the software design, optimizations and implementations of the
            MoonVision WeldScanner can be realized on site with little effort.
            Already after checking a few image/video data, you can be sure of
            the quality, further image data can also be added at a later point
            in time. All data is collected and stored centrally and can be
            retrieved by you at any time.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
